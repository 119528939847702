:root {
  --primary-color: #021766;
  --primary-color-rgb: 2, 23, 102;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"]:not(:disabled) {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  min-height: 1.2em;
  background-color: white;
}

input[type="date"]:focus {
  content: "text"
}